import React from 'react';
import BasePage from './BasePage';
import { Grid, Paper, Typography, Box } from '@mui/material'; // Importar Box aqui
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const CreditoBancos = () => {
  return (
    <BasePage title="Crédito para Bancos">
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, backgroundColor: '#006400', color: '#fff', display: 'flex', alignItems: 'center', borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <AccountBalanceIcon sx={{ fontSize: 40, marginRight: 2 }} />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>Crédito Disponível</Typography>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>R$ 2,000,000</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 600 }}>
              Detalhes de Crédito
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Nesta seção você encontra informações sobre os créditos disponíveis para operações bancárias, incluindo limites, condições e histórico de uso.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default CreditoBancos;
