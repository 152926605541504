import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Drawer, IconButton, Divider, Box, Tooltip } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard'; 
import InventoryIcon from '@mui/icons-material/Inventory';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Link, useNavigate } from 'react-router-dom';

const Sidebar = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/login'); // Redireciona para a página de login
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard' },
    { text: 'Estoque', icon: <InventoryIcon />, path: '/estoque' },
    { text: 'Liquidação', icon: <MonetizationOnIcon />, path: '/liquidacao' },
    { text: 'Crédito para bancos', icon: <AccountBalanceIcon />, path: '/credito-bancos' },
    { text: 'Relatórios', icon: <AssessmentIcon />, path: '/relatorios' },
  ];

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fundo semi-transparente para sobrepor a imagem
          backgroundImage: 'url(https://blog.chbagro.com.br/user-files/support/redactor-imgs/colheita-da-soja-9265.jpg)', // Imagem de fundo
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#006400', // Cor do texto para verde
          width: open ? '240px' : '80px', // Largura do menu encolhido
          transition: 'width 0.3s',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between', // Espaço entre os itens e o rodapé
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', // Sombra do menu
        },
      }}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding="8px"
          sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', width: '100%' }} // Fundo mais escuro para a área do topo
        >
          <img
            src="https://athix.com.br/img/bg_cooperfibra.png"
            alt="Cooperfibra"
            style={{ height: open ? '60px' : '30px', transition: 'height 0.3s' }}
          />
          <IconButton onClick={toggleDrawer} sx={{ color: '#FFB800', marginLeft: open ? 'auto' : '0' }}>
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
        <Divider sx={{ borderColor: '#FFB800', width: '100%' }} />
        <List sx={{ width: '100%' }}>
          {menuItems.map((item, index) => (
            <ListItem
              button
              key={item.text}
              component={Link}
              to={item.path}
              sx={{ justifyContent: open ? 'initial' : 'center' }}
            >
              <ListItemIcon sx={{ color: '#FFB800', minWidth: open ? '40px' : 'auto', justifyContent: 'center' }}>
                {item.icon}
              </ListItemIcon>
              {open && <ListItemText primary={item.text} sx={{ color: '#006400', opacity: open ? 1 : 0 }} />}
            </ListItem>
          ))}
        </List>
      </Box>
      
      <Box sx={{ mb: 2 }}> {/* Rodapé com ícones de Sair e Suporte */}
        <Divider sx={{ borderColor: '#FFB800', width: '100%' }} />
        <List sx={{ width: '100%' }}>
          <Tooltip title="Suporte" placement="right">
            <ListItem
              button
              component="a"
              href="https://www.athix.com.br"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ justifyContent: open ? 'initial' : 'center' }}
            >
              <ListItemIcon sx={{ color: '#25D366', minWidth: open ? '40px' : 'auto', justifyContent: 'center' }}>
                <WhatsAppIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Suporte" sx={{ color: '#FFFFFF', opacity: open ? 1 : 0 }} />} {/* Cor branca */}
            </ListItem>
          </Tooltip>
          <Tooltip title="Sair" placement="right">
            <ListItem
              button
              onClick={handleLogout}
              sx={{ justifyContent: open ? 'initial' : 'center' }}
            >
              <ListItemIcon sx={{ color: '#FF0000', minWidth: open ? '40px' : 'auto', justifyContent: 'center' }}>
                <ExitToAppIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Sair" sx={{ color: '#FFFFFF', opacity: open ? 1 : 0 }} />} {/* Cor branca */}
            </ListItem>
          </Tooltip>
        </List>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
