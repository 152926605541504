import React from 'react';
import { AppBar, Toolbar, IconButton, Menu, MenuItem, Box } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';

const Header = ({ open }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    navigate('/login');
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'transparent', // Fundo transparente
        boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.2)',
        width: open ? 'calc(100% - 200px)' : 'calc(100% - 60px)', // Largura dinâmica com base no estado da Sidebar
        marginLeft: open ? '200px' : '60px', // Margem para alinhar com a Sidebar
        transition: 'width 0.3s, margin 0.3s',
        height: '50px',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end', minHeight: '50px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            sx={{
              color: '#006400', // Verde bandeira para os ícones
              borderRadius: '50%',
              padding: '8px',
              marginRight: '10px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <NotificationsIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={handleMenu}
            sx={{
              color: '#006400', // Verde bandeira para os ícones
              borderRadius: '50%',
              padding: '8px',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
              },
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <AccountCircle />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{
              mt: '45px',
              '& .MuiPaper-root': {
                backgroundColor: '#006400', // Verde bandeira para o submenu
                color: '#FFFFFF', // Texto branco
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                borderRadius: '8px',
              },
            }}
          >
            <MenuItem onClick={handleClose}>Perfil</MenuItem>
            <MenuItem onClick={handleLogout}>Sair</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
