import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Estoque from './components/Estoque';
import Liquidacao from './components/Liquidacao';
import CreditoBancos from './components/CreditoBancos';
import Relatorios from './components/Relatorios';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword'; // Importar ForgotPassword

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} /> {/* Define a página de login como rota padrão */}
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} /> {/* Rota para "Esqueceu a Senha" */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/estoque" element={<Estoque />} />
        <Route path="/liquidacao" element={<Liquidacao />} />
        <Route path="/credito-bancos" element={<CreditoBancos />} />
        <Route path="/relatorios" element={<Relatorios />} />
      </Routes>
    </Router>
  );
}

export default App;
