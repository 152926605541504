import React from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    // Aqui você normalmente verificaria as credenciais antes de redirecionar
    navigate('/dashboard');
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password'); // Redireciona para a página de "Esqueceu a Senha"
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(https://blog.chbagro.com.br/user-files/support/redactor-imgs/colheita-da-soja-9265.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Fundo branco com opacidade
            padding: 4,
            borderRadius: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Sombra suave
            textAlign: 'center',
          }}
        >
          <img 
            src="https://athix.com.br/img/bg_cooperfibra.png" 
            alt="Coopergraos Logo" 
            style={{ height: '80px', marginBottom: '20px' }}
          />
          <Box mt={3}>
            <TextField label="CPF" variant="outlined" fullWidth margin="normal" />
            <TextField label="Senha" type="password" variant="outlined" fullWidth margin="normal" />
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              style={{ marginTop: '20px', backgroundColor: '#006400' }} 
              onClick={handleLogin}
            >
              Entrar
            </Button>
          </Box>
          <Box mt={2}>
            <Button color="secondary" onClick={handleForgotPassword}>Esqueceu a senha?</Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
