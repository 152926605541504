import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { Box, CssBaseline, Typography } from '@mui/material';

const BasePage = ({ title, children }) => {
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex', fontFamily: 'Poppins, sans-serif' }}>
      <CssBaseline />
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Box component="main" sx={{
        flexGrow: 1,
        padding: 3,
        paddingTop: '65px', // 15px additional padding for space below the header
        transition: 'margin 0.3s',
        marginLeft: open ? '240px' : '80px',
        backgroundColor: '#f4f4f4',
        minHeight: '100vh',
        overflowY: 'auto',
        scrollbarColor: '#006400 #e0e0e0',
        scrollbarWidth: 'thin',
      }}>
        <Header />
        <Box sx={{ my: 3 }}>
          <Typography variant="h4" color="textPrimary" gutterBottom sx={{ fontWeight: 600 }}>
            {title}
          </Typography>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default BasePage;
