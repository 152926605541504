import React from 'react';
import BasePage from './BasePage';
import { Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Box } from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

const columns = [
  { id: 'id', label: 'ID', width: 70 },
  { id: 'nome', label: 'Nome', width: 150 },
  { id: 'data', label: 'Data', width: 150 },
  { id: 'valor', label: 'Valor', width: 130 },
  { id: 'status', label: 'Status', width: 130 },
];

const rows = [
  { id: 1, nome: 'Relatório A', data: '01/01/2023', valor: 1200, status: 'Concluído' },
  { id: 2, nome: 'Relatório B', data: '15/02/2023', valor: 800, status: 'Pendente' },
  { id: 3, nome: 'Relatório C', data: '10/03/2023', valor: 500, status: 'Concluído' },
  { id: 4, nome: 'Relatório D', data: '22/04/2023', valor: 1500, status: 'Em Andamento' },
  { id: 5, nome: 'Relatório E', data: '18/05/2023', valor: 950, status: 'Concluído' },
];

const exportToPDF = () => {
  const doc = new jsPDF();
  doc.text('Relatórios Financeiros', 14, 10);
  doc.autoTable({
    startY: 20,
    head: [columns.map(col => col.label)],
    body: rows.map(row => columns.map(col => row[col.id])),
  });
  doc.save('relatorios-financeiros.pdf');
};

const exportToExcel = () => {
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatórios');
  XLSX.writeFile(workbook, 'relatorios-financeiros.xlsx');
};

const printTable = () => {
  window.print();
};

const Relatorios = () => {
  return (
    <BasePage title="Relatórios">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 600 }}>
              Relatórios Financeiros
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 2 }}>
              Acesse aqui os relatórios financeiros detalhados, com análises de performance, comparações anuais e insights importantes para a tomada de decisões estratégicas.
            </Typography>
            <Box sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" color="primary" onClick={exportToPDF} sx={{ marginRight: 2 }}>
                Exportar para PDF
              </Button>
              <Button variant="contained" color="primary" onClick={exportToExcel} sx={{ marginRight: 2 }}>
                Exportar para Excel
              </Button>
              <Button variant="contained" color="primary" onClick={printTable}>
                Imprimir
              </Button>
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} style={{ minWidth: column.width }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id}</TableCell>
                      <TableCell>{row.nome}</TableCell>
                      <TableCell>{row.data}</TableCell>
                      <TableCell>{row.valor}</TableCell>
                      <TableCell>{row.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default Relatorios;
