import React from 'react';
import BasePage from './BasePage';
import { Grid, Paper, Typography, Box } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const Liquidacao = () => {
  return (
    <BasePage title="Liquidação">
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, backgroundColor: '#006400', color: '#fff', display: 'flex', alignItems: 'center', borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <MonetizationOnIcon sx={{ fontSize: 40, marginRight: 2 }} />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>Valor Total</Typography>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>R$ 150,000</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 600 }}>
              Detalhes de Liquidação
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Aqui você pode ver os detalhes das liquidações realizadas, incluindo valores, datas e status das transações.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default Liquidacao; // Certifique-se de que o componente está sendo exportado corretamente
