import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import { Box, CssBaseline, Typography, Grid, Paper } from '@mui/material';
import { GiWheat, GiCorn, GiCottonFlower } from 'react-icons/gi'; // Ícones de grãos
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar } from 'recharts';

const Dashboard = () => {
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "https://www.noticiasagricolas.com.br/widgets/noticias?subsecao=3,80,146,4,13,15&largura=auto&altura=auto&fonte=Arial%2C%20Helvetica%2C%20sans-serif&tamanho=10pt&cortexto=333333&corlink=006666&qtd=15&output=js";
    document.getElementById('widget-container').appendChild(script);
  }, []);

  const data = [
    { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Mar', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Apr', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'May', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Jun', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Jul', uv: 3490, pv: 4300, amt: 2100 },
  ];

  const dollarData = [
    { name: 'Jan', rate: 5.2 },
    { name: 'Feb', rate: 5.3 },
    { name: 'Mar', rate: 5.1 },
    { name: 'Apr', rate: 5.4 },
    { name: 'May', rate: 5.5 },
    { name: 'Jun', rate: 5.6 },
    { name: 'Jul', rate: 5.7 },
  ];

  return (
    <Box sx={{ display: 'flex', fontFamily: 'Poppins, sans-serif' }}>
      <CssBaseline />
      <Sidebar open={open} toggleDrawer={toggleDrawer} />
      <Box component="main" sx={{
        flexGrow: 1,
        padding: 3,
        paddingTop: '65px', // Ensure space below the header
        transition: 'margin 0.3s',
        marginLeft: open ? '240px' : '80px',
        backgroundColor: '#f4f4f4', 
        minHeight: '100vh',
        overflowY: 'auto',
        scrollbarColor: '#006400 #e0e0e0',
        scrollbarWidth: 'thin',
      }}>
        <Header open={open} />
        <Box sx={{ my: 3 }}>
          <Typography variant="h5" color="textPrimary" gutterBottom sx={{ fontWeight: 600 }}>
            Painel de Controle
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={3}>
              <Paper sx={{
                padding: 2,
                backgroundImage: 'url(https://img.freepik.com/fotos-premium/por-do-sol-em-um-prado-rural-cores-vibrantes-em-todos-os-lugares-ia-generativa_188544-13135.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', 
                backgroundBlendMode: 'multiply', 
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Escurecer o fundo
              }}>
                <GiWheat size={40} style={{ marginRight: '10px' }} />
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>Total de Soja</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>50,000 tons</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper sx={{
                padding: 2,
                backgroundImage: 'url(https://img.freepik.com/fotos-gratis/crescimento-natural-de-culturas-de-milho-de-fazendas-rurais-gerado-pela-ia_188544-151680.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', 
                backgroundBlendMode: 'multiply', 
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Escurecer o fundo
              }}>
                <GiCorn size={40} style={{ marginRight: '10px' }} />
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>Total de Milho</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>30,000 tons</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper sx={{
                padding: 2,
                backgroundImage: 'url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQogL-98M6akgwJttulJXOg4Z1lcJ8bPagFmQ&s)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', 
                backgroundBlendMode: 'multiply', 
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Escurecer o fundo
              }}>
                <GiCottonFlower size={40} style={{ marginRight: '10px' }} />
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>Total de Algodão</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>20,000 tons</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper sx={{
                padding: 2,
                backgroundColor: '#006400',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              }}>
                <AccountBalanceIcon sx={{ fontSize: 30, marginRight: 2 }} />
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>Créditos</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>R$ 5,000,000</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Paper sx={{
                padding: 2,
                backgroundColor: '#006400',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 2,
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              }}>
                <AttachMoneyIcon sx={{ fontSize: 30, marginRight: 2 }} />
                <Box>
                  <Typography variant="h6" sx={{ fontWeight: 500 }}>Cotação do Dólar</Typography>
                  <Typography variant="h6" sx={{ fontWeight: 700 }}>R$ 5,50</Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        {/* Gráficos e Widget */}
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" color="textPrimary" gutterBottom sx={{ fontWeight: 600 }}>
              Desempenho de Produção
            </Typography>
            <Paper sx={{ padding: 3, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', mb: 3 }}>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart
                  data={data}
                  margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="pv" stroke="#FFB800" activeDot={{ r: 8 }} />
                  <Line type="monotone" dataKey="uv" stroke="#347A27" />
                </LineChart>
              </ResponsiveContainer>
            </Paper>
          </Box>
          <Box sx={{ flexGrow: 1, mb: 3 }}>
            <Typography variant="h6" color="textPrimary" gutterBottom sx={{ fontWeight: 600 }}>
              Cotação do Dólar
            </Typography>
            <Paper sx={{ padding: 3, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={dollarData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="rate" fill="#347A27" />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Box>
          <Box sx={{ marginLeft: 3, width: 'auto' }}>
            <Typography variant="h6" color="textPrimary" gutterBottom sx={{ fontWeight: 600 }}>
              Notícias Agrícolas
            </Typography>
            <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
              <div id="widget-container"></div>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
