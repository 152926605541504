import React from 'react';
import BasePage from './BasePage';
import { Grid, Paper, Typography, Box } from '@mui/material'; // Importar Box aqui
import InventoryIcon from '@mui/icons-material/Inventory';

const Estoque = () => {
  return (
    <BasePage title="Estoque">
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, backgroundColor: '#006400', color: '#fff', display: 'flex', alignItems: 'center', borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <InventoryIcon sx={{ fontSize: 40, marginRight: 2 }} />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 500 }}>Total de Produtos</Typography>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>1200 itens</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" color="textPrimary" sx={{ fontWeight: 600 }}>
              Detalhes de Produtos
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 2 }}>
              Esta seção exibe detalhes sobre o estoque atual, incluindo quantidade de produtos, categorias e status de disponibilidade.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default Estoque;
